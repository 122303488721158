<template>
  <div>
    {{ effectif }}
  </div>
</template>

<script>
export default {
    props: {
        object: {type: Object, required: true}
    },
    computed: {
        effectif(){
            if(this.object !== null) return this.object.members.length
            return 0
        }
    }

}
</script>

<style>

</style>